import React from "react";

// Components
import Layout from "../components/layout/Layout";
import Hero from "../components/modules/Hero";
import OurServices from "../content/OurServices";
import Testimonials from "../components/modules/Testimonials";

const ourservices = () => {
  return (
    <Layout title="Our Services">
      <Hero
        title="Development made easy"
        subText="From full stack development to infrastructure management, we operate as a full-service development studio. With an extensive list of trusted industry contacts we’re equipped to help you use code to empower your business. "
        color="yellow"
      />
      <OurServices showTitle={false} />
      <Testimonials />
    </Layout>
  );
};

export default ourservices;
